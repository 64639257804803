<template>
  <v-navigation-drawer
    left
    v-on="$listeners"
    v-bind="$attrs"
    :width="450"
    fixed
    floating
    class="elevation-1"
    style="z-index: 2500"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <content-layout>
        <cz-button
          text
          :icon-src="mdiClose"
          :icon-size="18"
          color="info"
          no-paddings
          :hover-effect="false"
          @click="$emit('close')"
        />
        <v-card flat>
          <v-card-title
            class="text-h6 mt-6 font-weight-semibold d-flex justify-space-between align-center"
          >
            <div>{{ barTitle }}</div>
            <cz-button
              v-if="filterIsActive"
              text
              :icon-src="mdiCloseCircleOutline"
              :title="$t('common.clearAllFilters')"
              @click="clearAllFilters"
            />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-form @submit.prevent="onFilter">
              <div class="d-flex flex-column" style="gap: 20px">
                <slot name="prepend-fields" />
                <template v-for="field in filterFieldsLocal">
                  <cz-form-field
                    :key="field.fieldname"
                    :label="field.label"
                    :icon-src="field.icon"
                  >
                    <validation-provider
                      :rules="field.rules || ''"
                      :name="field.label"
                      v-slot="{ errors }"
                    >
                      <cz-input
                        v-model="field.value"
                        dense
                        clearable
                        :error-messages="errors"
                        :placeholder="field.placeholder"
                        :disabled="
                          field.disabledHandler
                            ? field.disabledHandler(filterFieldsLocal)
                            : field.disabled
                        "
                        :type="field.inputType || 'text'"
                        v-if="field.type === 'input'"
                        v-bind="field.props"
                        :outlined="false"
                      />

                      <cz-date-input
                        v-else-if="field.type === 'date'"
                        v-model="field.value"
                        dense
                        :error-messages="errors"
                        :placeholder="field.placeholder"
                        :disabled="
                          field.disabledHandler
                            ? field.disabledHandler(filterFieldsLocal)
                            : field.disabled
                        "
                        clearable
                        v-bind="field.props"
                        :outlined="false"
                      />

                      <cz-new-date-picker
                        v-else-if="field.type === 'datepicker'"
                        v-model="field.value"
                        dense
                        :placeholder="field.placeholder"
                        :range="field.operator === 'bt'"
                        open-menu-on-click
                        :error-messages="errors"
                        :disabled="
                          field.disabledHandler
                            ? field.disabledHandler(filterFieldsLocal)
                            : field.disabled
                        "
                        :outlined="false"
                        clearable
                        v-bind="field.props"
                      />

                      <cz-autocomplete
                        v-else-if="field.type === 'autocomplete'"
                        v-model="field.value"
                        :error-messages="errors"
                        :placeholder="field.placeholder"
                        dense
                        :outlined="false"
                        :items="
                          field.itemsHandler
                            ? field.itemsHandler(filterFieldsLocal)
                            : field.items
                        "
                        :item-text="field.itemText"
                        :item-value="field.itemValue"
                        :multiple="field.multiple"
                        :disabled="
                          field.disabledHandler
                            ? field.disabledHandler(filterFieldsLocal)
                            : field.disabled
                        "
                        :use-chips="field.multiple"
                        chip-color="buttonSecondary"
                        small-chips
                        v-bind="field.props"
                      />

                      <cz-input-tags
                        v-else-if="field.type === 'tags'"
                        v-model="field.value"
                        :items="
                          field.itemsHandler
                            ? field.itemsHandler(filterFieldsLocal)
                            : field.items
                        "
                        :outlined="false"
                        :placeholder="field.placeholder"
                        :item-text="field.itemText"
                        :item-value="field.itemValue"
                        dense
                        :disabled="
                          field.disabledHandler
                            ? field.disabledHandler(filterFieldsLocal)
                            : field.disabled
                        "
                        small
                        v-bind="field.props"
                      />

                      <cz-autocomplete
                        v-else-if="field.type === 'vendors'"
                        v-model="field.value"
                        dense
                        :hint="$t('common.autocomplete')"
                        hide-no-data
                        :error-messages="errors"
                        no-filter
                        :append-icon="null"
                        :outlined="false"
                        :items="vendors"
                        combobox
                        removeable
                        :loading="serachingVendors"
                        :placeholder="
                          $t('customer.orders.vendorSearchPlaceholder')
                        "
                        v-debounce:600ms="onVendorsSearch"
                        item-text="businessName"
                        item-value="_id"
                        return-object
                        clearable
                        :disabled="field.disabled"
                        v-bind="field.props"
                      />
                      <cz-autocomplete
                        v-else-if="field.type === 'users'"
                        v-model="field.value"
                        dense
                        :hint="$t('common.autocomplete')"
                        hide-no-data
                        :error-messages="errors"
                        no-filter
                        :append-icon="null"
                        :items="users"
                        combobox
                        removeable
                        :outlined="false"
                        :loading="searchingUsers"
                        :placeholder="$t('common.usersSearch')"
                        v-debounce:600ms="onUsersSearch"
                        item-text="fullName"
                        item-value="_id"
                        return-object
                        clearable
                        :disabled="field.disabled"
                        v-bind="field.props"
                      />
                    </validation-provider>
                  </cz-form-field>
                </template>

                <cz-button
                  large
                  :title="$t('common.filterBarButtonTitle')"
                  color="info"
                  type="submit"
                  :disabled="invalid"
                  class="mt-4"
                />
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </content-layout>
    </validation-observer>
  </v-navigation-drawer>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout.vue';
import {
  CzButton,
  CzInput,
  CzAutocomplete,
  CzFormField,
  CzNewDatePicker,
  CzDateInput,
  CzInputTags
} from '@/components';
import { mdiClose, mdiCloseCircleOutline, mdiArrowLeft } from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import { getUsers, getVendors } from '@/core/api';
import isEqual from 'lodash.isequal';
import { activeFiltersCount } from '@/shared/services/filters.service';
export default {
  name: 'FiltersBar',
  components: {
    ContentLayout,
    CzButton,
    CzInput,
    CzNewDatePicker,
    CzAutocomplete,
    CzInputTags,
    CzDateInput,
    CzFormField
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    accountId: {
      type: String
    },
    filterFields: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    barTitle() {
      if (!this.title) {
        return this.$t('common.filtersBarTitle');
      } else {
        return this.title;
      }
    },
    filterIsActive() {
      return activeFiltersCount(this.filterFields) > 0;
    }
  },
  data() {
    return {
      mdiClose,
      mdiArrowLeft,
      mdiCloseCircleOutline,
      filterFieldsLocal: null,
      serachingVendors: false,
      vendors: [],
      searchingUsers: false,
      users: []
    };
  },
  methods: {
    onFilter(clear = false) {
      this.$emit('on-filter', this.filterFieldsLocal, clear);
    },
    clearAllFilters() {
      this.filterFieldsLocal.forEach((item) => {
        item.value = '';
      });
      this.onFilter(true);
    },
    async onUsersSearch(value) {
      if (!value || !this.accountId) {
        this.users = [];
        return;
      }

      try {
        this.searchingUsers = true;
        const { data: users } = await getUsers({
          search: value,
          accountId: this.accountId
        });
        this.users = users;
      } finally {
        this.searchingUsers = false;
      }
    },
    async onVendorsSearch(value) {
      if (!value) {
        this.vendors = [];
        return;
      }
      try {
        this.serachingVendors = true;
        this.vendors = await getVendors({
          search: value,
          isTest: false
        });
      } finally {
        this.serachingVendors = false;
      }
    }
  },
  watch: {
    filterFields: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!isEqual(value, this.filterFieldsLocal)) {
          this.filterFieldsLocal = cloneDeep(value);
        }
      }
    }
  }
};
</script>

<style></style>
